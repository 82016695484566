<template>
  <div class="dynamic-tables">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />
    <Widget>
      <DataTable
        class="p-datatable-sm p-datatable-striped p-datatable-gridlines"
        :value="getElectricians"
        :paginator="true"
        :filters="filters"
        :loading="loading"
        responsiveLayout="scroll"
        :rows="500"
        dataKey="id"
        :rowHover="true"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[25, 50, 100, 500]"
        currentPageReportTemplate="Zeige {first} bis {last} von {totalRecords} Einträgen"
      >
        <template #header>
          <div class="table-header">
            <div class="table-header-left">
              <h1 class="page-title">{{ $t('electricians') }}</h1>
            </div>
            <div class="table-header-right">
              <!-- <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText type="text" v-model="filters['global']" :placeholder="$t('search')" />
              </span> -->
              <div class="button-right">
                <router-link
                  class="btn btn-success mb-2 right"
                  :to="{ name: 'ElectricianCreatePage' }"
                >
                  <i class="fa fa-plus-circle mr-2"></i>
                  {{ $t('electricianCreate') }}
                </router-link>
              </div>
            </div>
          </div>
        </template>
        <template #empty>
          {{ $t('no_data_found') }}
        </template>
        <template #loading>
          {{ $t('loading') }}
        </template>

        <Column field="number" header="#" sortable filterMatchMode="contains">
          <template #body="slotProps">
            <router-link
              :to="{
                name: 'ElectricianEditPage',
                params: { electricianNumber: slotProps.data.number },
              }"
              >{{ slotProps.data.number.toString().padStart(4, '0') }}</router-link
            >
          </template>
          <template #filter>
            <InputText type="text" v-model="filters['number']" class="p-column-filter" />
          </template>
        </Column>

        <Column field="name" :header="$t('name')" sortable filterMatchMode="contains">
          <template #filter>
            <InputText type="text" v-model="filters['name']" class="p-column-filter" />
          </template>
        </Column>

        <Column field="zip" :header="$t('zip')" sortable filterMatchMode="startsWith">
          <template #filter>
            <InputText type="text" v-model="filters['zip']" class="p-column-filter" />
          </template>
        </Column>
        <Column field="city" :header="$t('city')" sortable filterMatchMode="contains">
          <template #filter>
            <InputText type="text" v-model="filters['city']" class="p-column-filter" />
          </template>
        </Column>
      </DataTable>
    </Widget>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Widget from '@/components/Widget/Widget';
import Breadcrumbs from '@/components/Breadcrumbs';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';

export default {
  name: 'ElectriciansPage',
  components: {
    Breadcrumbs,
    DataTable,
    Widget,
    InputText,
    Column,
  },
  data() {
    return {
      loading: true,
      filters: {},
    };
  },
  computed: {
    ...mapGetters(['isLoading', 'getElectricians']),
    breadcrumbs() {
      return [{ name: 'Home', route: { name: 'home' } }, { name: this.$t('electricians') }];
    },
  },
  methods: {
    ...mapActions(['fetchElectricians']),
  },
  async created() {
    await this.fetchElectricians();
    this.loading = false;
  },
};
</script>

<style scoped lang="scss">
::v-deep .p-datatable .p-datatable-thead > tr > th:nth-child(1),
::v-deep .p-datatable .p-datatable-tbody > tr > td:nth-child(1) {
  width: 60px;
  text-align: center;
}
::v-deep .p-datatable .p-datatable-thead > tr > th:nth-child(3),
::v-deep .p-datatable .p-datatable-tbody > tr > td:nth-child(3) {
  width: 80px;
  text-align: center;
}

.table-header {
  display: flex;
  justify-content: space-between;
}
.table-header-left {
  display: flex;
  justify-content: flex-start;
}
.table-header-right {
  display: flex;
  justify-content: flex-end;
  column-gap: 1em;
}
</style>
